export default [
	{
		id: 'the-lucky-ones',
		poster: '/videos/the-lucky-ones.jpg',
		src: 'https://www.youtube.com/embed/hzW6qCTJp-I',
		title: 'The Lucky Ones (_Animals:&nbsp;Vol.&nbsp;1_)',
	},
	{
		id: 'die-young',
		poster: '/videos/die-young.png',
		src: 'https://www.youtube.com/embed/eQjWmR_-g6Q',
		title: 'Die Young (_Wilderness_)',
	},
	{
		id: 'pareidolia',
		src: 'https://www.youtube.com/embed/3iuuVdowobQ',
		title: 'Pareidolia (_Wilderness_)',
	},
	{
		id: 'the-afterlives',
		src: 'https://www.youtube.com/embed/JxxYfJVBdmM',
		title: 'The Afterlives (_Wilderness_)',
	},
	{
		id: 'weird-weather',
		poster: '/videos/weird-weather.jpg',
		src: 'https://www.youtube.com/embed/pex1AeSwF4Y',
		title: 'Weird Weather (_Wilderness_)',
	},
	{
		id: 'an-episode',
		poster: '/videos/an-episode.jpg',
		src: 'https://www.youtube.com/embed/En1Lt-WisOI',
		title: 'An Episode (_Wilderness_)',
	},
	{
		id: 'cedarland',
		poster: '/videos/cedarland.png',
		src: 'https://www.youtube.com/embed/-jp4NCEPHnQ',
		title: 'Cedarland (_Cedarland_)',
	},
]
