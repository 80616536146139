import React from 'react'
import { Helmet } from 'react-helmet'

import style from './index.module.css'

function Contact() {
	return (
		<main className={ style.Contact }>
			<Helmet title="Contact" />
			<header className={ style.Header }>
				<h2>Contact</h2>
				<h3><a href="mailto:band@palaxytracks.com">band@palaxytracks.com</a></h3>
			</header>
		</main>
	)
}

export default Contact
