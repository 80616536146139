import React from 'react'
import moment from 'moment'
import Markdown from 'markdown-to-jsx'
import { Link, useRoute } from 'wouter'
// import { useMediaQuery } from 'react-responsive'

// Content
import releases from 'content/releases' // eslint-disable-line import/no-unresolved

// Style
import style from './index.module.css'

/**
 * Timeline
 *
 * @function Timeline
 * @returns {Node} React Node
 */
function Timeline() {
	const wrapper = React.useRef()
	const [, params] = useRoute('/release/:release')

	// const isLargeDesktop = useMediaQuery({ query: '(min-width: 1920px)' })

	return (
		<nav
			className={ style.Timeline }
			ref={ wrapper }
		>
			<ul className={ style.Navigation }>
				{
					releases.map(rel => (
						<li
							className={ [
								style.Release,
								params && params.release === rel.slug
									? style['Release--selected']
									: null,
							].join(' ') }
							key={ rel.title }
						>
							<span className={ style.Date }>
								{ moment(rel.date).format('Y') }
							</span>
							<Link to={ `/release/${rel.slug}` }>
								<a
									className={ style.Title }
									href={ `/album/${rel.slug}` }
								>
									<Markdown>
										{ rel.htmlTitle }
									</Markdown>
								</a>
							</Link>
						</li>
					))
				}
			</ul>
		</nav>
	)
}

export default Timeline
