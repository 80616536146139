import React from 'react'
import { Helmet } from 'react-helmet'

import style from './index.module.css'

function Four() {
	return (
		<main className={ style.Four }>
			<Helmet title="Four" />
			<h1>404</h1>
		</main>
	)
}

export default Four
