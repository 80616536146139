import React from 'react'
import { detect } from 'detect-browser'
import { Link } from 'wouter'

import { ReactComponent as Logo } from 'assets/images/horse.svg'

import style from './index.module.css'

const browser = detect()

function Header() {
	return (
		<header className={ style.Wrapper }>
			<h1 className={ style.Title }>
				<svg
					dominantBaseline="hanging"
					height="784"
					preserveAspectRatio="xMinYMin"
					viewBox="0 0 290 392"
					width="580"
				>
					<text
						fontFamily="Tungsten-Bold"
						fontSize="130.5px"
						fontWeight="normal"
						x="0"
						y="-9"
					>
						<tspan
							dy={
								browser.name === 'ios'
								|| browser.name === 'firefox'
								|| browser.name === 'safari'
								|| browser.name === 'crios'
									? 100
									: 0
							}
							x="-4"
						>
							PALAXY
						</tspan>
						<tspan dy="100" x="-2">TRACKS</tspan>
						<tspan dy="100" x="-1">1997<tspan letterSpacing="-3">——</tspan></tspan>
						<tspan dy="100" x="-2">2017</tspan>
					</text>
				</svg>
			</h1>
			<nav className={ style.Nav }>
				<Link to="/">
					<a className={ style.Logo } href="/">
						<Logo />
					</a>
				</Link>
				<ul className={ style.Links }>
					<li>
						<Link to="?videos">Videos</Link>
					</li>
					<li>
						<Link to="/contact">Contact</Link>
					</li>
				</ul>
			</nav>
		</header>
	)
}

export default Header
