/* eslint-disable max-len */

export default [
	{
		htmlTitle: 'Caught&nbsp;By&nbsp;a New&nbsp;Invention',
		title: 'Caught By a New Invention',
		slug: 'caught-by-a-new-invention',
		downloadLink: 'https://share.getcloudapp.com/GGupmKQ2',
		date: '1998-01-01',
		images: [
			{
				height: 1425,
				image: '/albums/caught-by-a-new-invention.png',
				width: 1426,
			},
		],
		tracks: [
			'Touch the Magic Lantern',
			'Terrible Year',
			'The Lucky Ones',
			'Magnum',
			'Caught By a New Invention',
			'Thirteen',
			'Red',
			'A Little More Haven',
			'The Girl From Tater Junction',
			'Labrador',
			'Salt Lake City',
			'Thanks Governor',
			'The Problem With People',
			'Boy’s Life',
		],
		producers: [
			'Brandon Durham',
		],
		performers: [
			{
				name: 'Greg Wallace',
				instruments: 'Vocals, Tambourine',
			},
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Keyboard, Drums, Bass, Percussion',
			},
			{
				name: 'Jay Benson',
				instruments: 'Guitar',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Nathan Roberts',
				instruments: 'Drums',
			},
		],
		content: `Palaxy Tracks began as a home recording project called The 700 Club in 1995. Together with Greg Wallace and Jason Masters, we recorded two collections under that title: _The 700 Club_ and _Fun For the Whole Family_.

For _Caught By a New Invention,_ our third release (and first “official” album), we would experience a personnel change (Jason moved away and was replaced by Jay Benson, and Nathan Roberts joined on drums). With these changes came a sort of maturing. We graduated from a casual home recording project to a full-fledged band.

<figure class="img--full"><img src="/images/archive/party.jpg" height="1435" width="2104" /><figcaption>The 700 Club, circa 1996. From left to right: Greg Wallace, Brandon Durham, Nathan Roberts, Jason Masters.</figcaption></figure>

##### “Touch the Magic Lantern”

This opening song is an attempt at a general introduction to the album, and was later re-recorded with a different lineup (see _Insound Tour Support Series, Vol. 24_).

##### “The Lucky Ones”

The first of many songs (and one of my favorites) written about my mother, who passed away when I was 14. I don’t typically like to be explicit in the lyrics when writing about my mother — largely to avoid becoming too redundant — as evidenced in this song.

##### “Magnum”

One of the worst song titles we’ve dreamed up, this song features a whacko Phaser guitar pedal we found hidden away in an attic space after Greg’s roommate moved out to attend clown school. The labels and logo were worn off of the facing, and I’ve never seen another pedal like it since, so I couldn’t even tell you what the brand name was. (Did it even exist?) This pedal had a mind of its own, and would sound different virtually every time it was used (see also the noisy “guitar solos” in “Thanks, Governor” and “Red”), or not even work at all.

##### “Caught By a New Invention”

Recorded during the _Austin Stories_ sessions (see “Austin Stories 1” and “Austin Stories 2” in the _Animals, Vol. 2_ release). I remember being head-over-heels about Trans Am at the time, which is painfully apparent here.

##### “A Little More Haven”

Bedhead and Luna were other strong influences at the time, which is likely where the “A Little More Haven” sound was lifted from. I still like something about this song, as simple as it is.

##### “The Girl From Tater Junction”

Yes, there really was a Girl From Tater Junction.

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'The Family Tree',
		title: 'The Family Tree',
		slug: 'the-family-tree',
		downloadLink: 'https://share.getcloudapp.com/BluxndGr',
		date: '2000-01-01',
		images: [
			{
				height: 2000,
				image: '/albums/the-family-tree.jpg',
				width: 2000,
			},
		],
		tracks: [
			'The Family Tree',
			'If I Were a Julie London',
		],
		producers: [
			'Ted Cho',
			'Brandon Durham',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Bass, Keyboard, Drums',
			},
			{
				name: 'Ted Cho',
				instruments: 'Guitar',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Nathan Roberts',
				instruments: 'Drums',
			},
		],
		content: `I have to admit, I think we made a mistake with this one. For our first and only 7” we chose to feature a song that wasn’t really representative of the band — a song that soon became my least favorite Palaxy Tracks song (and still is) — and pushed a much better song to the B side.

Nonetheless, it was to be our introduction to the world, and many years later would be wonderfully recreated by <strong>Me, You & My Metronome</strong> for the <em>Animals</em> release.

It should be virtually impossible to find a copy of this release out in the world, but it does pop up at the strangest times.

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'The&nbsp;Long Wind&nbsp;Down',
		title: 'The Long Wind Down',
		slug: 'the-long-wind-down',
		downloadLink: 'https://share.getcloudapp.com/d5uRm6G9',
		date: '2001-01-01',
		images: [
			{
				height: 2000,
				image: '/albums/the-long-wind-down.jpg',
				width: 2000,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/bs/album/cedarland/284068587',
			},
		],
		tracks: [
			'We Go Way Back',
			'I’m Swimming',
			'If I Were a Julie London',
			'A Million Things To Do',
			'This Is the World',
			'Malory Is Dead',
			'The Golden Days',
			'Maxwell’s Army',
			'The Family Tree',
			'The Fountainhead',
			'Me and the Weather',
			'From “Royals”',
			'Cats For Drowning',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitars, Bass, Drums, Keyboard',
			},
			{
				name: 'Ted Cho',
				instruments: 'Guitar, Lap Steel, Bass',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Nathan Roberts',
				instruments: 'Drums',
			},
		],
		content: `The first proper Palaxy Tracks album, recorded in Ted Cho’s aptly named Stu Stu Studio (and at home in the case of “If I Were a Julie London” and “From ‘Royals’”) over the course of several weeks, where many of the songs were recorded live to tape with few overdubs.

The lack of money and resources while working on <small>TLWD</small> sparked a lot of creative solutions. I wanted a sound like a crackling radio or the run-out area of an old <small>LP</small> for “From ‘Royals’,” but I didn’t have a turntable. Instead, we ended up rubbing an old dish towel on a Shure 58 and cranked the high-mids, ending up with some sort of hybrid sound. We also didn’t have a piano, so we snuck into the <small>UT</small> Music Building (which really wasn’t guarded anyway) with our gear and turned one of the piano booths into a temporary studio.

##### “We Go Way Back” and “Malory Is Dead”

Once again the subject of my mother’s death appears, but this time a bit less vague. With the exception of the vocals, “We Go Way Back” was recorded live to tape with no overdubs.

##### “I’m Swimming”

Much of the layered sound here (especially in the chorus) came from hours and hours of aimless noodling on guitars and keyboards and chasing down what was working. Not really writing “parts,” per se. More like adding accents. This is a luxury you don’t have when renting a studio as it would be too cost prohibitive. Because we were in Ted’s private studio, we were able to take our time and experiment.

##### “If I Were a Julie London”

When we decided to change the name of the band we all took turns adding ideas to the pile. One idea submitted by Greg was “The Julie Londons,” which was universally loved but deemed too twee for the sound of the band. I still loved the name and wanted to immortalize it somehow, so I used it in a song title. The title of the song has nothing to do with its subject matter, which is about an old coworker that was a chronic one-upper.

I recorded this song in my bedroom with the intention of bringing it to the band, but I got a serious case of demo-itis and couldn’t capture the same feel as I did in the original, so we used the demo. Most of the other songs on the album were recorded digitally to <small>ADAT</small> while this one was done on a Tascam Portastudio 488 8-track cassette recorder.

##### “A Million Things To Do” and “The Golden Days”

Around the time I was writing these songs I was seeing a man 19 years my senior. He had only just come to terms with being gay but hadn’t yet come out of the closet. So our time together was often a little restrained and stealthy. I didn’t want to pressure him, but I did write these two songs inspired by our circumstances.

Interesting tidbit: Ted’s guitar parts for “The Golden Days” were recorded in an elevator shaft. He put his guitar at the bottom and strung mics up through a hole in the wall — one up close and the other higher up in the shaft.

##### “This Is the World”

Have you ever been in love with someone that you can’t have? I have the solution. Write a jovial pop song about it.

This song was recorded live to tape, with the exception of the vocals. I remember a roommate had just moved out and my guitar sounded great in his now empty room, so I sat down and wrote this song along with “Maxwell’s Army,” which both use very similar guitar techniques.

##### “Maxwell’s Army”

We did something with this song that I’ve always felt guilty about. I couldn’t get a vocal sound I liked and couldn’t figure out why. Then I realized it was because the key was a tad too low for my register, making my voice sound too full. So we re-recorded the vocal with the playback speed slowed down a bit. When we listened back we pulled the playback speed back up to normal, in turn making my vocal track take on a slightly condensed quality. This felt like cheating to me at the time, but now I’m just proud that we came up with the solution.

##### “Me and the Weather”

I honestly can’t recall what inspired this song, or even what I originally intended it to be about, but the clear message is overcoming adversity.

I’ve always loved the way this recording came out. I’m not sure why it’s so dynamically different than the other songs. It’s our only song that uses drop D tuning.

##### “Cats For Drowning”

I have to talk about the end of this song (the end being when the song breaks down to just one guitar, and on from there). I’ve always been kind of proud of it, and it’s always been one of my favorite bits of music to play live. It was also a blast to record.

Each of the four guitars used a completely different guitar/amp setup with the biggest, fattest guitar using a tiny Traynor amp. The guitar that comes in at the break was interesting. Ted played it and he wanted to hit the strings hard, but every time he did the other strings would make a terrible _clunk_ sound. We tried taping them but that didn’t work. So I literally pulled the top four strings off of the fret board and held them while Ted played his part. Hey, it worked.`,
	}, {
		htmlTitle: 'Insound Tour&nbsp;Support Series&nbsp;Vol.&nbsp;24',
		title: 'Insound Tour Support Series Vol. 24',
		slug: 'insound-tour-support-series-vol-24',
		downloadLink: 'https://share.getcloudapp.com/DOuBbmKn',
		date: '2002-04-29',
		images: [
			{
				height: 1649,
				image: '/albums/insound-tour-support.jpg',
				width: 1649,
			},
		],
		elsewhere: [],
		tracks: [
			'Long Arms',
			'The Occupant',
			'Man About Town',
			'Long Ride',
			'Endive',
			'Nobody Said Anything',
			'The Awful Truth',
			'Holly Grove',
			'Touch the Magic Lantern',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Bass, Piano, Moog',
			},
			{
				name: 'Brad Murph',
				instruments: 'Moog',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass, Vocals, Guitar',
			},
			{
				name: 'Ben Kane',
				instruments: 'Drums, Vocals, Guitar',
			},
		],
		content: `In 2002 the online music outlet Insound invited us to participate in their Tour Support series, a collection of previously unreleased songs meant to be sold on tour, and printed by Insound. We didn’t really have enough leftover music that we wanted to share, so we recorded a few new songs for the collection.

##### “Long Arms”

Written for long-time friends The Jennifers of Austin’s independent radio show Ear Candy and features Ben Kane, our newest member at the time, on drums and backing vocals.

##### “The Occupant”

Deals yet again with my mother’s death and features the Yamaha CP70, an “electric grand piano” we acquired under somewhat questionable circumstances.

##### “Man About Town”

Much like “Walking Backwards” and “Posthumous,” this song deals with unrequited love between friends.

##### “Long Ride”

A cover of a Shearwater song from their first release on our old label, _The Dissolving Room._

##### “Nobody Said Anything”

This is the first song we wrote and recorded with our newest member, Brad Murph. This would have been shortly before leaving Austin for Chicago.

##### “The Awful Truth”

A demo for the song of the same name that appears on Cedarland.

##### “Holly Grove”

The last song recorded with original vocalist Greg Wallace, and the last song we recorded in Ted’s Stu Stu Studio (it would later burn down with most of the other units in the building, an event that to this day is considered likely to be an insurance scam).

##### “Touch the Magic Lantern”

A rerecording of a song that originally appears on Caught By a New Invention. This was a sort of warmup before recording “Holly Grove.”

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'Cedarland',
		title: 'Cedarland',
		slug: 'cedarland',
		downloadLink: 'https://share.getcloudapp.com/YEuO7Bz5',
		date: '2003-04-29',
		images: [
			{
				height: 900,
				image: '/albums/cedarland.jpg',
				width: 900,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/bs/album/cedarland/284068587',
			}, {
				service: 'Spotify',
				link: 'https://open.spotify.com/album/4gsINcck91Wu4uZu8urmnk',
			}, {
				service: 'Amazon',
				link: 'https://www.amazon.com/Cedarland-Palaxy-Tracks/dp/B00008J2HV/ref=ntt_mus_ep_dpi_2',
			},
		],
		tracks: [
			'The Sediment',
			'The Wasp',
			'Walking Backwards',
			'Girls On Bikes',
			'Posthumous',
			'Cedarland',
			'Aim For Providence',
			'To The Chicago Abyss',
			'Good Morning Nurse',
			'The Awful Truth',
			'Song About a Ghost',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Keyboard, Piano, Programming',
			},
			{
				name: 'Brad Murph',
				instruments: 'Guitar, Lap Steel',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Joe Rowsey',
				instruments: 'Drums',
			},
			{
				name: 'Ted Cho',
				instruments: 'Lap Steel, Mandolin',
			},
			{
				name: 'Evelyn Weston',
				instruments: 'Musical Saw',
			},
			{
				name: 'Will R. Sheff',
				instruments: 'Piano',
			},
			{
				name: 'Jonathan Meiburg',
				instruments: 'Wurlitzer, Piano, Banjo, Accordion',
			},
			{
				name: 'Chad Gifford',
				instruments: 'Violin',
			},
			{
				name: 'Jeffrey Carleton',
				instruments: 'Piano',
			},
			{
				name: 'Jeff Hoskins',
				instruments: 'Drums, Percussion',
			},
			{
				name: 'Dave Max Crawford',
				instruments: 'Mellotron',
			},
		],
		content: `_Cedarland_ is our first release after moving to Chicago, and is largely about my home town of Cedar Park, Texas, circa 1973–1987. Back when it was still the land of tire swings; lightning bugs; sweet tea; train tracks; honeysuckle; and sunset curfews.

For the cover art, I started talking to artist Rick Myers (responsible for most all of Doves’ album covers) before recording was complete. He wanted to try and pick some themes from the lyrics and history in our conversations. One tidbit he was interested in was the fact that part of the reason I was leaving Texas was because I’d become painfully allergic to the pollen in the air there.

The final product features a sculpture of cedar trees made by Rick with an overlay of “dust” or pollen, paired with hand-lettered titles, also by Rick. Before this point I couldn’t begin to envision what the album art might look like, but Rick was able to really translate the overarching themes into a single image so perfectly that, upon initial viewing, I couldn’t begin to imagine the album with any other art.

##### “The Sediment”

The first track on the record was directly inspired by a song by <small>NAS</small> — no kidding. I heard his song (I couldn’t tell you which) over the speakers in a restaurant and it carried a very similar beat, so I started humming over it and imagined a very simple, monotone vocal over it. The closest thing to Hip Hop you’ll hear in any <small>PT</small> record.

##### “Walking Backwards” and “Posthumous”

Both of these songs hit on something that I think many people in the <small>LGBTQ</small> community can relate to. Everyone has fallen for someone they could never have. It’s a different kind of pain when that person is a close friend that identifies as straight but has conflicting feelings for you. “Walking Backwards” talks about a friendship that moves into something unhealthy, and ultimately changes forever because it can’t be defined, and because the other party is too afraid to come to terms with their feelings.

“Posthumous” moves a bit further and focuses on the aftermath of this kind of relationship, and the things we do when we’re in that kind of pain.

##### “Girls On Bikes”

What may very well be our most popular song, “Girls On Bikes,” was written in another fit of creative clarity. It’s inspired by the memory of a short-lived family tradition where during a single 1970s summer my family would occasionally all ride bikes together at the end of the day. We were very rarely even in the same room together, and this tradition was one of the only things that made my mother truly happy.

##### “Cedarland”

This song relied almost entirely on broken gear. While recording parts for several songs, Brad ran his lap steel through a vintage Echoplex that had some unique and unpredictable quirks, and the end product was a beautiful, rich drone in the key of C. When I heard it I was instantly inspired to build on top of it. During the mixing process, the drone was steeped heavily in a plate reverb that, when pushed hard, created these ghostly creaks and pops and breaths that added a whole other dimension to the drone.

##### “To the Chicago Abyss”

This song was written after we decided to move to Chicago, but before we actually left Austin. It’s built on top of a guitar technique that’s created when you move your pick in a circular motion at the top of the neck and, when paired with a bit of delay, creates a drone that mimics that of strumming very, very quickly. Cheating, in a sense.

The song is very much about leaving home, learning to be an adult, and the way your relationships with friends, family, and home changes as a result.

##### “The Awful Truth”

By the time I was 13 my mother had been suffering from Lupus and Cancer for many years. During the course of her last Summer and Winter I was her primary caretaker, responsible for feeding her, giving her medication, and all of the other less-than-glamorous duties you take on when caring for someone that can’t care for themselves. As you can imagine, this was a very, very dark period. One that will haunt me forever.

Halfway through the winter she was taken to the hospital where she would pass away soon after. I’ve battled with a lot of guilt over the years, my subconscious telling me that I’m somehow partly responsible. “The Awful Truth” is largely about that guilt, and the responsibilities I had as a 13-year-old to keep my mother healthy and happy while she was in my care.

##### “Song About a Ghost”

This song was recorded in a very patchwork fashion. The basic tracks were recorded at Clava Studio while virtually every other instrument was recorded in a different place. Evelyn Weston played the Musical Saw in our building’s stairwell where the landlord kept coming out mid-take asking how she played it. All percussion — including a 100-dollar tambourine — was recorded in New York City by Jeff Hoskins. He swore that tambourine sounded better than any other he’d heard, and he was right. So, don’t skimp on your tambourines.

Jonathan Meiburg’s banjo and piano parts were recorded in our living room, as were my vocals and Ted’s mandolin. Max Crawford recorded the Mellotron bits at his home. Somehow, despite the schizophrenic recording process, I think it came out sounding very unified.

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'Twelve Rooms',
		title: 'Twelve Rooms',
		slug: 'twelve-rooms',
		downloadLink: 'https://share.getcloudapp.com/o0uPWZ1b',
		date: '2005-01-01',
		images: [
			{
				height: 1425,
				image: '/albums/twelve-rooms.jpg',
				width: 1425,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/us/album/twelve-rooms-special-edition/284003009',
			}, {
				service: 'Spotify',
				link: 'https://open.spotify.com/album/6SuniWAW4Co0Gqqk17k0Lb',
			}, {
				service: 'Tidal',
				link: 'https://tidal.com/browse/album/4900011',
			}, {
				service: 'Amazon',
				link: 'https://www.amazon.com/Twelve-Rooms-PALAXY-TRACKS/dp/B0009F446M/ref=ntt_mus_ep_dpi_1',
			},
		],
		tracks: [
			'Speech With Animals',
			'Grey Snake',
			'The Clarion Way',
			'Up My Sleeve',
			'Camera',
			'Lamplighter',
			'The Criminal Mind',
			'Seems So Long Ago, Nancy',
			'Me & You & Him',
			'Legs On the Ladder',
			'Dead Language',
			'Twelve Rooms',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Wurlitzer, Piano, Percussion',
			},
			{
				name: 'Brad Murph',
				instruments: 'Guitar, Percussion',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Ben Kane',
				instruments: 'Drums, Vocals, Guitar',
			},
			{
				name: 'Ted Cho',
				instruments: 'Guitar',
			},
			{
				name: 'Alison Chesley',
				instruments: 'Cello',
			},
			{
				name: 'Dave Max Crawford',
				instruments: 'Hammond, Pump Organ, French Horn, Mellotron',
			},
			{
				name: 'Frank Orrall',
				instruments: 'Vibraphone',
			},
			{
				name: 'Tim Rutili',
				instruments: 'Keyboards',
			},
		],
		content: `I’ve always had difficulty playing the quieter songs live. I can’t explain why, but it leaves me feeling too… exposed, and I can’t help but feel like everyone is about to leave the room en masse.

Because of this irrational fear, I was determined to make the next record something of a barn burner. At least in Palaxy Tracks’ terms. I felt like we needed to prove that Palaxy Tracks wasn’t just this sad, mopey band. To whom I needed to prove this, I’m not sure.

What resulted was something that maybe didn’t sound so much like Palaxy Tracks anymore, but was most definitely more fun to play live. I still really love the songs on this record. I can safely say that one or two are up there with other favorites.

There is one other mistake I made with this record. In the one-sheet _(a one page overview of the release that’s shared with critics)_ I mentioned that the songs on this record were loosely inspired by Raymond Carver when in fact it was only “Speech With Animals” and “Dead Language” that could stake that claim. It’s no secret that music critics tend to pull heavily from one-sheets in their reviews, and many of the critics turned it into some sort of comparison to Carver’s work. You need to be more strategic with your one-sheets, knowing that anything you say there can and will be used against you.

<figure class="img--full">
  <img src="/images/archive/tracking-twelve-rooms_1.jpeg" height="450" width="600" />
  <img src="/images/archive/tracking-twelve-rooms_2.jpeg" height="450" width="600" />
  <img src="/images/archive/tracking-twelve-rooms_3.jpeg" height="450" width="600" />
  <figcaption>Tracking <em>Twelve Rooms</em>. From left: Ted Cho and Brad Murph.</figcaption>
</figure>

##### “Speech With Animals” and “Dead Language”

So, both “Speech With Animals” and “Dead Language” were inspired by Raymond Carver’s _They’re Not Your Husband_. The former takes a more hopeful approach to the issue at hand, and the latter a more aggressive. I think both are just mildly effective. I believe the more effective music lives in the middle of this record, starting with…

##### “Grey Snake”

“Grey Snake” is a retelling of an urban legend that was passed around my hometown when I was little. There was a place called Blue Hole in a neighboring town where kids loved to hang out in the summer. It was a drainage tunnel that was accessible via a square opening in the creek bed on one side of a street. When entering the opening the force of the water would pull you through and spit you out the other side of the street. Needless to say, it was dangerous, and I believe the story was a tool to keep kids from hanging out there at night.

The story was about a woman that was accused of witchcraft (of course) and was being hunted by the authorities. She had two children with her and when she sensed she was near capture she drowned them in the river before the authorities could get to them, then she threw herself into the water. The story says that she haunts the creek every night looking for her children, and if you’re there she may take take you by mistake.

It worked.

Another interesting tidbit about “Grey Snake” is regarding the recording of the middle section, just after the breakdown. The root note of this section is B, so Ted put an A/B switch on my guitar, sent the second line to an amp in a vocal booth, and put an acoustic electric guitar that was tuned to B right smack in front of the amp. When I played my part the corresponding strings on the acoustic would vibrate and create this ethereal sound. Ted recorded the guitar’s output and we used this under everything else during the crescendo. You can’t really hear it, but you’d miss it if it was gone.

##### “Lamplighter”

I wrote this song in a fit of anger after experiencing a terrible betrayal by a friend. It’s the only song I can think of that I wrote in this state, but it did the trick in that it made me feel better and made the friend eventually apologize.

Recording “Lamplighter” was especially fun. Particularly the whole end section with the odd beat and bursts of feedback. Ted and I tried all kinds of combinations of pedals, amps, and guitars to get the sound we wanted. I think we ended up with a tiny Traynor amp (I believe?) cranked all the way and a good old-fashioned Memory Man.

There are several mixing tweaks I’d love to make to this song. The main guitar (left) is too quiet, and the whole end section has this very fast piano part (right) that I believe adds a lot of interest to this section, but we pushed it too far down in the mix.

##### “The Criminal Mind”

I don’t think it’s terribly unusual when an artists’ favorite song from their own catalogue does not rank high on the listeners’ list of favorites. “The Criminal Mind” happens to be one of my favorites that we’ve ever recorded. I can’t really explain why. I think it’s because I feel all of the patchwork parts of the song came together really well, and the mix is one of the few that I feel is about as good as we could get it. This is also one of very, very few songs where I’m not embarrassed about the lyrics.

The song is about an Aunt of mine that happened to be in an abusive relationship. Everyone was trying to convince her to leave but she was stuck. I had a dream around this time where I helped her “bury him down by the creek” and then go on the lam.

So, basically _Sleeping With the Enemy._

##### “Legs On the Ladder”

Another song born of anger, which isn’t a state I’m typically able to work with when writing. But it was important to reconcile this memory somehow. So why not do it for the whole world to hear?

When I met my first boyfriend I was 21 and still in the closet. He was 31, bisexual, and likely never to come out of the closet (although he convinced me otherwise). When I visited him I had to pretend to be a friend, then “leave” and climb through his window if I wanted to stay. He would put a chair against the door so nobody could surprise us.

He suggested we both come out of the closet individually and then invite all of our friends over for a party/celebration that weekend. So I did it. I came out to all of my friends, one-by-one, to a mostly positive response (I never heard from two of those friends again).

When we all converged for the party I found out that not only did he not come out to anyone, but he was also seeing two women on the side, both of which were at this party. So, many years later “Legs On the Ladder” was born and I suddenly felt better.

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'Twelve&nbsp;Rooms (Special&nbsp;Edition)',
		title: 'Twelve Rooms (Special Edition)',
		slug: 'twelve-rooms-special-edition',
		date: '2005-05-31',
		images: [
			{
				height: 600,
				image: '/albums/twelve-rooms-special-edition.jpg',
				width: 600,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/us/album/twelve-rooms-special-edition/284003009',
			}, {
				service: 'Spotify',
				link: 'https://open.spotify.com/album/6SuniWAW4Co0Gqqk17k0Lb',
			}, {
				service: 'Tidal',
				link: 'https://tidal.com/browse/album/4900011',
			}, {
				service: 'Amazon',
				link: 'https://www.amazon.com/Twelve-Rooms-PALAXY-TRACKS/dp/B0009F446M/ref=ntt_mus_ep_dpi_1',
			},
		],
		tracks: [
			'Speech With Animals',
			'Grey Snake',
			'The Clarion Way',
			'Up My Sleeve',
			'Camera',
			'Lamplighter',
			'The Criminal Mind',
			'Seems So Long Ago, Nancy',
			'Me & You & Him',
			'Legs On the Ladder',
			'Dead Language',
			'Twelve Rooms',
			'Cedarland (Live In Chicago, July 2005)',
			'Grey Snake (Octopus Project Remix)',
			'Wake Me Up',
			'Broken Glass (Twelve Rooms Outtake)',
			'I Won’t Be Home Tonight',
			'Speech With Animals (Demo)',
			'Me & You & Him (Daniel Knox)',
			'Camera (Roommate Remix)',
			'Non-Fiction',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Wurlitzer, Piano, Percussion',
			},
			{
				name: 'Brad Murph',
				instruments: 'Guitar, Percussion',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Ben Kane',
				instruments: 'Drums, Vocals, Guitar',
			},
			{
				name: 'Ted Cho',
				instruments: 'Guitar, Slide',
			},
			{
				name: 'Alison Chesley',
				instruments: 'Cello',
			},
			{
				name: 'Dave Max Crawford',
				instruments: 'Hammond, Pump Organ, French Horn, Mellotron',
			},
			{
				name: 'Frank Orrall',
				instruments: 'Vibraphone',
			},
			{
				name: 'Tim Rutili',
				instruments: 'Keyboards',
			},
			{
				name: 'Chuck Harling',
				instruments: 'Percussion',
			},
			{
				name: 'Toto Miranda',
				instruments: 'Remix (Drums, Keyboard, Programming)',
			},
			{
				name: 'Daniel Knox',
				instruments: 'Piano, Vocals',
			},
			{
				name: 'Kent Lambert',
				instruments: 'Remix (Programming)',
			},
		],
		content: `Shortly after the release of Twelve Rooms we found we had a handful of recordings that didn’t have a home, most of which were directly related to Twelve Rooms. Instead of letting them decompose in a drawer somewhere, we decided to put together a special digital release of Twelve Rooms with the extra material included.

##### “Cedarland (Live in Chicago, 2005)”

Opening the CD release show for Twelve Rooms with “Cedarland” seemed appropriate. This may actually be the only time we ever performed the song live.

##### “Grey Snake (Octopus Project Remix)”

We shared a label with The Octopus Project and played many, many shows together (I would later go on to join the band). When we were putting this release together I put out an invite for remixes or covers of any Twelve Rooms song. The Octopus Project took some of the more ambient tracks (see “Grey Snake” notes for the Twelve Rooms release) and the vocals from the recording and built a new song out of them.

##### “Wake Me Up”

Okay, I now realize just how often I write songs about falling in love with someone you can’t have. This is another one.

##### “Broken Glass (Twelve Rooms Outtake)”

Recorded during the Twelve Rooms sessions, “Broken Glass” was left out of the final cut because it stuck out like a sore thumb. We couldn’t find a spot anywhere in the final track order that made sense. Read cool things about this in the Animals, Vol. 2 notes.

##### “I Won’t Be Home Tonight”

Ted and I spent two days mixing Twelve Rooms with the final day going to about 5am. At some point during the night I wrote this song and, on a whim, decided to quickly record it. Ted threw up a single microphone, I did one take, and we were back to mixing. Not really worth including on an album, but I’m glad we got it on tape.

##### “Speech With Animals (Demo)”

Recorded in Ted’s basement apartment (and it sounds like it), we recorded this version of the song to be included in a local Chicago-based compilation.

##### “Me & You & Him (Daniel Knox)”

I did live sound during the five years I lived in Chicago. I heard a lot of… interesting music, but there were a few artists I did sound for that absolutely blew me away. Daniel Knox was one of them. I was so transfixed by his voice, and his dark observations wrapped in such beautiful songs. I was over the moon when he sent me this recording, and I’m still just as in love with it to this day.

##### “Camera (Roommate Remix)”

Roommate’s take on “Camera” is beautifully weird, just like Roommate. They were Youth Lagoon before Youth Lagoon was born.

##### “Non-Fiction”

The first and only time “Non-Fiction” was performed live. We closed the Twelve Rooms CD release show with this song. It’s one of the only live recordings of Palaxy Tracks that I can listen to. Steve Versaw of The M’s engineered this recording.

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'Wilderness',
		title: 'Wilderness',
		slug: 'wilderness',
		downloadLink: 'https://share.getcloudapp.com/BluxndAx',
		date: '2015-03-03',
		images: [
			{
				height: 1782,
				image: '/albums/wilderness.png',
				width: 1782,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/us/album/wilderness/966035820',
			}, {
				service: 'Spotify',
				link: 'https://open.spotify.com/album/3b48rJcVB2YpPLvgcoDiT8',
			}, {
				service: 'Tidal',
				link: 'https://tidal.com/browse/album/42184007',
			}, {
				service: 'Amazon',
				link: 'https://www.amazon.com/Wilderness-Palaxy-Tracks/dp/B00TQTCUPO/ref=sr_1_1',
			},
		],
		tracks: [
			'An Episode',
			'O R P H A N S',
			'Die Young',
			'There is a Sound',
			'Slow Dancing',
			'Pennies',
			'The Afterlives',
			'Non-Fiction',
			'Words in a Jar',
			'Bless This Mess',
			'Pareidolia',
			'Weird Weather',
		],
		performers: [
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Fender Rhodes, Programming, Mellotron, Mechanical Music Box, Keyboard',
			},
			{
				name: 'Devon Torrey Bryant',
				instruments: 'Bass, Guitar, Piano, Mellotron, Hammond B3, Moog Little Phatty Stage II, Fender Rhodes',
			},
			{
				name: 'Tony Sackett',
				instruments: 'Bass, Vibraphone, Vocals',
			},
			{
				name: 'Brett Whitacre',
				instruments: 'Drums',
			},
			{
				name: 'Ted Cho',
				instruments: 'Guitar',
			},
			{
				name: 'Max Crawford',
				instruments: 'Horn arrangement, Flügelhorn',
			},
			{
				name: 'Dave Smith',
				instruments: 'Flute, Bass Clarinet',
			},
			{
				name: 'Susan Voelz',
				instruments: 'String arrangement, Violin',
			},
			{
				name: 'Nora Barton',
				instruments: 'Cello',
			},
		],
		content: `After a 9-year hiatus from making music I was suddenly struck by the desire again. Up to this point I hadn’t wanted to touch a guitar, much less write a song.

Wilderness came to life after I emailed some quickly documented ideas to some friends back in Chicago. It’d been 9 years since I wrote my last complete song when all-of-a-sudden the songs started coming. Next thing I knew I was booking a flight to Chicago to record. No rehearsals, no discussions. Just went straight to work and made our decisions as we worked through the songs.

Something about the process was incredibly liberating, and playing with such talented musicians made it all flow so smoothly.

<figure class="img--full"><img src="/images/archive/wall-2-wall_1.jpeg" height="1001" width="1500" /><figcaption>Brett Whitacre playing a squeaky vibraphone at Wall-2-Wall.</figcaption></figure>

Wall-2-Wall, the studio where we recorded much of Wilderness, looks like the set of a 1970s dating gameshow. Or a snuff film. All red, purple, and blue shag carpeting covering the floors and walls. The main tracking room is built up on risers about 3 feet from the floor, and a stockpile of unique instruments that are just as old as the studio itself, if not older, are stored underneath for your perusing.

<figure class="img--full"><img src="/images/archive/wall-2-wall_2.jpeg" height="1001" width="1500" /><figcaption>Brett Whitacre, Brandon Durham, and Devon Torrey Bryant tracking basics at Wall-2-Wall.</figcaption></figure>

We pulled out a Vibraphone in desperate need of some love and attention, and a Mellotron with some interesting quirks that we ended up featuring on two songs.

##### “An Episode”

About half of _Wilderness_ was written in 2006, just before I left Chicago to move back home to Austin. “An Episode” was the last of that batch and was never performed live.

Devon played the quirky Mellotron on this song that had some ghost-in-the-machine qualities to it. Ever so often the tape for certain keys would slip, causing the note to warble audibly. After a period of time he figured out how to make it happen intentionally. It can be heard in this song just before the Fender Rhodes break in the middle.

##### “O R P H A N S”

Another song written in 2006, but the structure and pacing changed dramatically once we landed in the studio.

##### “Die Young”

_Wilderness_ was partially funded by a successful Kickstarter campaign. When setting the campaign up I made a marketing video with custom background music. I ended up liking the song so much I decided to build it out more and include it on the record.

This entire song was composed on an iPad using real samples of Mellotron sounds.

##### “There Is a Sound”

Not much to say for this song other than mentioning how amazing the horn arrangement by Dave Max Crawford is. It saved the song. The only thing I’d change here is to add a string bass.

##### “Slow Dancing”

This song is based on a home invasion I experienced when I was very young. I was at my cousin’s house with my sister. Our mother and aunt walked to a yard sale down the street, and while they were gone a man with a gun entered the home.

Me, my sister, and our three cousins hid in one of the showers with the bathroom door locked. He eventually found us and tried to kick the door in but was unsuccessful.

When they returned we told the story to my mother and aunt. They didn’t believe us until they saw waist high boot marks all over the bathroom door.

##### “Pennies”

I’d originally recorded vocals for this song but it turned out that it worked best as a short instrumental. The version with vocals can be heard on _Animals, Vol. 2_.

##### “The Afterlives”

When I was little — around 6 — I started having a recurring dream where I would wake up in a sunny bedroom surrounded by ghosts rising through the floors all the way up through the ceilings. As they floated up they would point in the direction they wanted me to walk in.

They eventually led me to our back door — a sliding glass door. When I looked out I saw my family hanging from the tree in our back yard, and the ghosts were circling them. No matter what I tried I could never open that door.

##### “Non-Fiction”

Part of the 2006 batch of songs, “Non-Fiction” actually did make it into some of our live sets (you can hear a live recording of it in both “Twelve Rooms, Special Edition” and “Animals, Vol. 2”).

We actually did have a plum tree in our yard when I was young, and my best friend Andretti and I would eat the plums that had ripened and fallen to the ground.

This song deals with secret relationships, something not at all uncommon when growing up gay. When you or the person you’re seeing are still closeted, you’ll do whatever it takes to protect yourselves. In “Non-Fiction,” one of the parties never actually comes out and goes on to get married, but they can’t quite let go completely.

When Andretti’s sister started calling me sissy he stopped hanging out with me. I kind of wind that story into the above as I saw an interesting parallel.

##### “Words In a Jar”

The first song I wrote after a 9-year hiatus from writing. I was never able to figure out the perfect arrangement here, but I still think it’s a sweet little song. I’m especially fond of Tony Sackett’s backing vocals throughout.

##### “Pareidolia”

The last of the batch of 2006 songs, “Pareidolia” is loosely about being stuck in your home town, too afraid to make a change.

##### “Weird Weather”

I had a cat named Thurston for 18 years. He was almost human and had an uncanny ability to detect and lift your mood. He eventually suffered from kidney failure, requiring two subcutaneous injections of fluid a day, along with medications.

By the time I decided he was in too much pain he’d shrunken in size. He would hide under the bed or cover himself with my clothing — a sign that he was ready to go.

“Weird Weather” is about my last day with Thurston. We threw his medications away (“… the wind can take the strong medicine…”) and gave him treats of cheese, heavy cream, whipped cream, and whatever else he wanted.

This was the last song that we recorded for _Wilderness_, and was one of the most enjoyable studio experiences I’ve ever had. For the ending, Tony, Devon and I set up in the same room and played our parts together. In the same room was a grand piano. Ted had placed a brick on the damper pedal to keep it open, then mic’ed the piano’s harp which would then pick up the sympathetic tones from the guitar amps. It created a nice bed of sound that you could pull up in the mix just under the guitars.

<p class="signature">— B</p>
		`,
	}, {
		htmlTitle: 'Animals, Vol.&nbsp;1',
		title: 'Animals, Vol.&nbsp;1',
		slug: 'animals-vol-one',
		downloadLink: 'https://share.getcloudapp.com/12uvXgDb',
		date: '2017-02-28',
		images: [
			{
				height: 6000,
				image: '/albums/Final-d_1.png',
				width: 6000,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/us/album/animals-vol-2-rare-and-unreleased-music/1209778428',
			}, {
				service: 'Spotify',
				link: 'https://open.spotify.com/album/2m8bCTSseO7rJ9M1ptSM7O',
			}, {
				service: 'Tidal',
				link: 'https://tidal.com/browse/album/70825208',
			}, {
				service: 'Amazon',
				link: 'https://www.amazon.com/Animals-Vol-Covers-Palaxy-Tracks/dp/B06X9LZ4M6/ref=sr_1_2',
			},
		],
		tracks: [
			'The Lucky Ones (feat. Martin Ruby)',
			'Speech with Animals (feat. Sabers)',
			'Maxwell’s Army (feat. The Indoorsmen)',
			'Girls on Bikes (feat. San Saba County)',
			'Grey Snake (feat. Nightlock)',
			'The Golden Days (feat. Montopolis)',
			'An Episode (feat. The Glum Sisters)',
			'Me and the Weather (feat. Wil Zanni)',
			'A Little More Haven (feat. Port of Call)',
			'The Sediment (feat. Nate Wey)',
			'The Family Tree (feat. Me, You & My Metronome)',
			'This Is the World (feat. Chip Pope & Mike Henry)',
			'Thanks, Governor (feat. Embassies)',
			'I’m Swimming (feat. Excited States)',
			'Aim for Providence (feat. Steve Garcia)',
			'Cats for Drowning (feat. Rachel Goldstar)',
			'Terrible Year (feat. Scott Treude)',
			'Girls on Bikes (feat. Sean O’Neil)',
			'Dead Language (feat. Tymoff)',
			'If I Were a Julie London (feat. Beaty Wilson)',
			'Me & You & Him (feat. Daniel Knox)',
			'Maxwell’s Army (feat. Francisco is the Man)',
			'A Million Things to Do (feat. Warm Ones)',
			'The Awful Truth (feat. The Bright & Hollow Sky)',
			'Cedarland (feat. Toto Miranda)',
		],
		performers: [
			{ name: 'Martin Ruby' },
			{ name: 'Sabers' },
			{ name: 'The Indoorsmen' },
			{ name: 'San Saba County' },
			{ name: 'Nightlock' },
			{ name: 'Montopolis' },
			{ name: 'The Glum Sisters' },
			{ name: 'Wil Zanni' },
			{ name: 'Port of Call' },
			{ name: 'Nate Wey' },
			{ name: 'Me, You & My Metronome' },
			{ name: 'Chip Pope & Mike Henry' },
			{ name: 'Embassies' },
			{ name: 'Excited States' },
			{ name: 'Steve Garcia' },
			{ name: 'Rachel Goldstar' },
			{ name: 'Scott Treude' },
			{ name: 'Sean O’Neil' },
			{ name: 'Tymoff' },
			{ name: 'Beaty Wilson' },
			{ name: 'Daniel Knox' },
			{ name: 'Francisco is the Man' },
			{ name: 'Warm Ones' },
			{ name: 'The Bright & Hollow Sky' },
			{ name: 'Toto Miranda' },
		],
		content: `I close my eyes and I’m 7-years old, feeding our orphaned calf by bottle before catching the bus; helping rescue malnourished dogs from neglectful neighbors; wrestling with our oversized Spanish goat for fun; racing passing cargo trains on my horse Misty; sharing an ice cream cone with Puppy Jane, my German Shepard mix with the crooked ear.

I open my eyes and I’m 45, having a mature conversation with our 12-year old Chocolate Labrador Junebug about death.

They’ve always been here.

<figure class="img--full"><img src="/images/archive/unused-art.gif" height="755" width="1006" /><figcaption>Unused art for Animals.</figcaption></figure>

Around November of 2016 I decided to put together a collection of rarities and unreleased music. It seemed an appropriate way to mark the end of Palaxy Tracks. I purchased an old Tascam Portastudio 488 and started mining the archives (mostly unmarked cassette tapes and <small>DVRs</small> in random forgotten shoe boxes) to see if I could find anything worth sharing.

I found quite a bit, but I was having trouble getting excited about releasing just the rarities, so I redefined the project and decided to make it a release for charity with all proceeds going to the David Sheldrick Wildlife Trust in Africa. I also put out an open invite to the world to submit a Palaxy Tracks cover by a certain date to be included in the release. I hoped to have at least a small handful of submissions, but to my surprise I ended up with 25.

With 25 covers and 33 songs for the rarities, it was clear this would have to be a 2-part release. Volume 1 would feature 25 different artists from all over the globe covering the Palaxy Tracks song of their choice, and Volume 2 would be 22 years of Palaxy Tracks rarities.

<p class="signature">— B</p>`,
	}, {
		htmlTitle: 'Animals, Vol.&nbsp;2',
		title: 'Animals, Vol.&nbsp;2',
		slug: 'animals-vol-two',
		downloadLink: 'https://share.getcloudapp.com/geuA5mZG',
		date: '2017-02-27',
		images: [
			{
				height: 6000,
				image: '/albums/Final-d_2.png',
				width: 6000,
			},
		],
		elsewhere: [
			{
				service: 'iTunes',
				link: 'itms://music.apple.com/us/album/animals-vol-2-rare-and-unreleased-music/1209778428',
			}, {
				service: 'Spotify',
				link: 'https://open.spotify.com/album/2m8bCTSseO7rJ9M1ptSM7O',
			}, {
				service: 'Tidal',
				link: 'https://tidal.com/browse/album/70825208',
			}, {
				service: 'Amazon',
				link: 'https://www.amazon.com/Animals-Vol-Covers-Palaxy-Tracks/dp/B06X9LZ4M6/ref=sr_1_2',
			},
		],
		tracks: [
			'Love Is All Around',
			'Would You Lay with Me (In a Field of Stone)',
			'Tinsel Town',
			'Hernando de Soto',
			'The Sticking Place',
			'Austin Stories 1',
			'Austin Stories 2',
			'Salt Lake City (Demo)',
			'Girl from Tater Junction (Demo)',
			'A Little More Haven',
			'Caught by a New Invention',
			'King of Bees (Outtake)',
			'Salt Lake City',
			'Terrible Year',
			'The Lucky Ones',
			'Collapse (Outtake)',
			'Holly Grove',
			'October',
			'Girls on Bikes (Demo)',
			'Nobody Said Anything (Outtake)',
			'Posthumous (Demo)',
			'The Awful Truth (Demo)',
			'Dead Language (Outtake)',
			'Shelter (Outtake)',
			'Long Arms',
			'The Occupant',
			'Broken Glass (Outtake)',
			'Non-Fiction (Live)',
			'Slow Dancing (Demo)',
			'Pennies (Alternate mix)',
			'The Afterlives (Remix)',
			'Words in a Jar (Rough mix)',
			'From a Photograph (Outtake)',
		],
		performers: [
			{
				name: 'Greg Wallace',
				instruments: 'Vocals',
			},
			{
				name: 'Jason Masters',
				instruments: 'Guitar, Bass, Drums, Keyboard',
			},
			{
				name: 'Brandon Durham',
				instruments: 'Vocals, Guitar, Bass, Drums, Keyboard, Percussion, Piano, Programming, Mechanical Music Box',
			},
			{
				name: 'Jay Benson',
				instruments: 'Guitar',
			},
			{
				name: 'Keith Grap',
				instruments: 'Bass',
			},
			{
				name: 'Nathan Roberts',
				instruments: 'Drums',
			},
			{
				name: 'Benn Cissner',
				instruments: 'Guitar, Keyboard',
			},
			{
				name: 'Ted Cho',
				instruments: 'Guitar, Mandolin, Bass',
			},
			{
				name: 'Brad Murph',
				instruments: 'Guitar, Moog',
			},
			{
				name: 'Ben Kane',
				instruments: 'Drums, Vocals',
			},
			{
				name: 'Chuck Harling',
				instruments: 'Drums',
			},
			{
				name: 'Brett Whitacre',
				instruments: 'Drums',
			},
			{
				name: 'Tony Sackett',
				instruments: 'Bass, Vocals, Vibraphone',
			},
			{
				name: 'Devon Torrey Bryant',
				instruments: 'Moog, Bass',
			},
		],
		content: `Around November of 2016 I decided to put together a collection of rarities and unreleased music. It seemed an appropriate way to mark the end of Palaxy Tracks. I purchased an old Tascam Portastudio 488 and started mining the archives (mostly unmarked cassette tapes and <small>DVRs</small> in random forgotten shoe boxes) to see if I could find anything worth sharing.

I found quite a bit, but I was having trouble getting excited about releasing just the rarities, so I redefined the project and decided to make it a release for charity with all proceeds going to the David Sheldrick Wildlife Trust in Africa. I also put out an open invite to the world to submit a Palaxy Tracks cover by a certain date to be included in the release. I hoped to have at least a small handful of submissions, but to my surprise I ended up with 25.

With 25 covers and 33 songs for the rarities, it was clear this would have to be a 2-part release. Volume 1 would feature 25 different artists from all over the globe covering the Palaxy Tracks song of their choice, and Volume 2 would be 22 years of Palaxy Tracks rarities.

##### “Love Is All Around”

Beginning with the home recording that started it all in 1995 (a gauzy take on the Mary Tyler Moore theme song, “Love Is All Around,” now drenched in 20-year-old tape hiss), _Animals Vol. 2_ plays out like a linear map of the history of Palaxy Tracks. Every composite of members is represented here together for the first time, and many recordings thought (and in some cases hoped) long lost were recovered for this collection.

Side story: Greg and I once drunkenly serenaded Bob Mould with “Love Is All Around” outside of his Austin home in the middle of the night. He turned his lights out.

The first three tracks for Vol. 2 come from the very first 700 Club album, which if I remember correctly was untitled. With the exception of “Tinsel Town” every song on this album was a cover, featuring country and pop artists from the 50s through the 70s.

<figure class="img--full">
<img src="/images/archive/memo.png" height="2094" width="3004" />
<figcaption>Notes from Greg to me regarding the mixes for the first 700 Club album.</figcaption>
</figure>

##### “Hernando de Soto” and “The Sticking Place”

Both come from the second 700 Club album, titled _Fun For the Whole Family_. This album was a schizophrenic collection of haphazardly thrown-together songs that didn’t really make any sense or share a vision. Believe me when I say that these are two of the better songs.

##### “Austin Stories 1 & 2”

At some point around this period I was invited to submit a couple of songs to <small>MTV</small> for consideration for their first sitcom, _Austin Stories_ (it helped that I am great friends with Chip Pope, one of the writers/actors). I threw together “Austin Stories 1 & 2” based on the description provided in the request. They weren’t used and ended up on a shelf for 20 years.

##### “Salt Lake City”

The demo for “Salt Lake City” is, to this day, one of my favorite things I’ve ever recorded. It’s imperfect and sounds terrible, but there is something about it that just captures a time and place for me, and the performance on the slide guitar with the delay, moles and all, still takes me away. I was never able to really recreate that mood for the album version of the song (from _Caught By a New Invention_), but Greg’s vocals brought something else out for me that was equally sentimental.

##### “Caught By a New Invention”

This song was written and recorded on the same day that I did the _Austin Stories_ songs, and was essentially a love letter to Trans Am. I am not a professional drummer. However, I am an amateur drummer, as clearly illustrated here.

##### “The Lucky Ones”

When my mother was going through radiation therapy for Cancer I would accompany her to her appointments. I wrote “The Lucky Ones” based on something that happened during one of her treatments. She told me that the machine that directs the radiation into your body (linear accelerator) makes a clicking sound that she always counts (“… ringing in her ears, the cold machine…”). On this particular day it made twice the number of clicks than it usually does, and that the receptionist got her name wrong. I think she dropped it and never inquired, but I’ll never forget the fear in her eyes when we left the treatment center.

<figure class="img--full"><img src="/images/archive/press.jpg" height="2066" width="2537" /><figcaption>Press photo circa 1999. From left to right: Brandon Durham, Keith Grap, Nathan Roberts, Ted Cho.</figcaption></figure>

##### “Holly Grove” and “October”

These two were written specifically for inclusion on a local compilation and marks not only the last recording with Greg Wallace as vocalist, but also the first with Ted Cho as second guitarist. I’ve always been fond of “Holly Grove.” As a song it’s stuck with me over the years, but I’m also really happy with the way Greg’s vocals came out, as well as the way the guitars converge at the tail end of the song.

##### “Nobody Said Anything”

Named after a Raymond Carver story, “Nobody Said Anything” was written sometime between _The Long Wind Down_ and _Cedarland_, just before leaving Austin for Chicago, and is the first song that new guitarist Brad Murph performed on (although he plays the Moog here). I’m not sure why we didn’t record it for possible inclusion on _Cedarland_.

##### “Posthumous”

Written and recorded in the span of a couple of hours (a somewhat unusual event in the land of Palaxy Tracks), this song is about a chance encounter with a friend that slowly destroyed the friendship. In this demo the idea was to include bursts of noise throughout the end and have the rhythm section break apart underneath, but for unknown reasons we went in a more hypnotic and slightly frenzied direction for the final recording.

##### “Dead Language”

The very first song we recorded during the _Cedarland_ sessions appears here in an incomplete state, as it was left. I believe it fell by the wayside because we could never come up with a way to round it out. We later recycled the title for a song on _Twelve Rooms_.

##### “Shelter”

This is a song we’d been bouncing around for months. We never felt like it was complete but couldn’t figure out what to do with it. For anyone familiar with the song “Grey Snake” from _Twelve Rooms_, you may recognize the breakdown in the middle section here. This section actually made its way into four different songs over the course of several years, finally finding a home in “Grey Snake” where it surely belongs.

##### “Broken Glass”

Written in the studio during the _Twelve Rooms_ sessions and promptly removed from inclusion on the album. I love this little song, but it just didn’t work with any other song.

An interesting tidbit: The song has two bass parts. The first appears in the right speaker and was performed on a very detuned hollow body guitar. The second — an actual bass guitar — comes in on the second verse at dead center.

##### “Non-Fiction”

A song I wrote before leaving Chicago for Austin to join The Octopus Project. This particular performance was recorded during the <small>CD</small> release show for _Twelve Rooms_. Virtually nothing changed in the song between this performance and the recording for _Wilderness_. Chuck Harling did a wonderful job creating the minimal percussion for this song.

##### “The Afterlives”

For this remix I wrote a small JavaScript program to programmatically remix the song. It could detect changes in the music, cut them into bars, then reshuffle them “intelligently,” attempting to create a natural-sounding ebb and flow. With every running of the script you would get a new version.

##### “From a Photograph”

I had high hopes for this song, but in the end just couldn’t figure out how to arrange it, or even really how to perform it. This particular version is entering the land of “twee” for me and I want off the ride pronto.

<p class="signature">— B</p>`,
	},
]
