import React from 'react'
import PropTypes from 'prop-types'

const ThemeContext = React.createContext({})
const ThemeConsumer = ThemeContext.Consumer

const initialState = {
	theme: null,
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'theme':
			return { ...state, theme: action.theme }
		default: return state
	}
}

/**
 * ThemeProvider
 *
 * @param   {Node} children  App
 * @returns {Node} React Node
 */
function ThemeProvider({ children }) {
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const value = { state, dispatch }
	return (
		<ThemeContext.Provider value={ value }>{ children }</ThemeContext.Provider>
	)
}

ThemeProvider.propTypes = {
	children: PropTypes.element.isRequired,
}

export { ThemeContext, ThemeProvider, ThemeConsumer }
