/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import { Helmet } from 'react-helmet'
import Markdown from 'markdown-to-jsx'
import { useInView } from 'react-intersection-observer'
import ReactGA from 'react-ga'
import { usePrevious } from 'utils'
import { find, get } from 'lodash'

// Content
import releases from 'content/releases' // eslint-disable-line import/no-unresolved

import { ThemeConsumer } from '../../context'

import PictureFrame from '../PictureFrame'

import style from './index.module.css'

const duration = 3000

/**
 * Release
 *
 * @param   {object} release  Full release details
 * @returns {Node} React Node
 */
function Release({ release }) {
	const wrapper = React.useRef()

	const requestedRelease = find(releases, rel => rel.slug === release)

	const [ref, inView] = useInView({
		threshold: 0,
	})

	// Save as the previous release
	const prevRelease = usePrevious(get(requestedRelease, 'slug', null))

	if (!requestedRelease) {
		return (
			<article className={ style.Release }>
				<Helmet title="404" />
				<header className={ style.Header }>
					<h2>No release by that name</h2>
				</header>
			</article>
		)
	}

	return (
		<Transition
			// appear
			in={ prevRelease !== requestedRelease.slug }
			timeout={ duration }
		>
			{ status => (
				<ThemeConsumer>
					{ () => (
						<article
							className={ [style.Release, style[`Release--${status}`]].join(' ') }
							ref={ wrapper }
						>
							<Helmet>
								<title>
									{ `Release → ${requestedRelease.title.replace(/&nbsp;/gi, '').replace(/<br( \/|\/|\s)?>/gi, '')}` }
								</title>
								<link
									as="image"
									href={ requestedRelease.images[0].image }
									rel="preload"
								/>
							</Helmet>
							<header className={ style.Header }>
								<h2>
									<Markdown>
										{ requestedRelease.title }
									</Markdown>
								</h2>
								<h3><time>{ requestedRelease.date.substring(0, 4) }</time></h3>
							</header>
							<figure
								className={ style.Figure }
								ref={ ref }
							>
								<PictureFrame
									height={ requestedRelease.images[0].height }
									slug={ requestedRelease.slug }
									width={ requestedRelease.images[0].width }
								/>
								<img
									alt={ `Cover art for ${requestedRelease.title.replace(/&nbsp;/gi, '')}` } // eslint-disable-line max-len
									className={ inView ? style['IMG--inView'] : style.IMG }
									height={ requestedRelease.images[0].height }
									src={
										inView
											? requestedRelease.images[0].image
											: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=' // eslint-disable-line max-len
									}
									width={ requestedRelease.images[0].width }
								/>
							</figure>
							<div className={ style.Content }>
								<nav className={ style.Nav }>
									{
										requestedRelease.slug !== 'twelve-rooms-special-edition' && requestedRelease.downloadLink ? (
											<a
												download
												href={ requestedRelease.downloadLink }
												onClick={ () => {
													ReactGA.event({
														category: 'ReleaseNav',
														action: 'Download',
														label: `/get/${requestedRelease.slug}`,
													})
												} }
												rel="noreferrer"
												target="_blank"
											>
												Download
											</a>
										) : null
									}
									{
										requestedRelease.elsewhere
										&& requestedRelease.elsewhere.map(item => (
											<a
												href={ item.link }
												key={ `elsewhere-${item.service}` }
												onClick={ () => {
													ReactGA.event({
														category: 'ReleaseNav',
														action: item.service,
														label: item.link,
													})
												} }
											>
												{ item.service }
											</a>
										))
									}
								</nav>
								<div className={ style.Details }>
									<Markdown>
										{ requestedRelease.content }
									</Markdown>
									{
										requestedRelease.producers ? (
											<>
												<h4>Producer(s)</h4>
												<p>{ requestedRelease ? requestedRelease.producers.join(', ') : null }</p>
											</>
										) : null
									}
									{
										requestedRelease.performers ? (
											<>
												<h4>Performers</h4>
												<dl className={ style.Performers }>
													{
														requestedRelease.performers.map(performer => (
															<React.Fragment key={ performer.name }>
																<dt>{ performer.name }</dt>
																<dd>{ performer.instruments }</dd>
															</React.Fragment>
														))
													}
												</dl>
											</>
										) : null
									}
									<h4>
										{ requestedRelease.tracks.length }
										{ ' ' }Track{ requestedRelease.tracks.length === 1 ? '' : 's' }
									</h4>
									<ul className={ style.Tracks }>
										{ requestedRelease.tracks.map(item => (
											<li key={ item }>{ item }</li>
										)) }
									</ul>
									<h4>Questions or comments?</h4>
									<p>
										Drop us a note using
										{ ' ' }
										<a
											href="https://palaxytracks.typeform.com/to/BieZDY"
											onClick={ () => {
												ReactGA.event({
													category: 'TypeForm',
													action: 'Open',
												})
											} }
											rel="noopener noreferrer"
											target="_blank"
										>
											this form
										</a>
										{ ' ' }
										and we’ll get back to you just as soon as we’re able.
									</p>
								</div>
							</div>
						</article>
					) }
				</ThemeConsumer>
			) }
		</Transition>
	)
}

Release.propTypes = {
	release: PropTypes.string.isRequired,
}

export default Release
