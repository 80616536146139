import React from 'react'

import style from './index.module.css'

function Home() {
	return (
		<main className={ style.Home } />
	)
}

export default Home
