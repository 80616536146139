import React from 'react'
import PropTypes from 'prop-types'

import style from './index.module.css'

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

const PictureFrame = ({
	height,
	innerBlockSizeRatio,
	slug,
	strokeWidth,
	width,
}) => {
	const innerBlockWidth = innerBlockSizeRatio * width
	const innerBlockHeight = innerBlockSizeRatio * height

	const ax = (width / 2) - (innerBlockWidth / 2)
	const ay = (height / 2) - (innerBlockHeight / 2)
	const bx = ax
	const by = height * 0.2 // make inner square 20% size of outer
	const cx = width / 2
	const cy = ay
	const dx = ax
	const dy = height / 2
	const ex = (width / 2) - innerBlockWidth
	const ey = ay

	const innerBlock = [
		{
			x: ax,
			y: ay,

			tl: {
				x1: 0,
				x2: ax,
				y1: 0,
				y2: ay,
			},
			tr: {
				x1: width,
				x2: ax + innerBlockWidth,
				y1: 0,
				y2: ay,
			},
			bl: {
				x1: 0,
				x2: ax,
				y1: height,
				y2: ay + innerBlockHeight,
			},
			br: {
				x1: width,
				x2: ax + innerBlockWidth,
				y1: height,
				y2: ay + innerBlockHeight,
			},
		}, {
			x: bx,
			y: by,

			tl: {
				x1: 0,
				x2: bx,
				y1: 0,
				y2: by,
			},
			tr: {
				x1: width,
				x2: bx + innerBlockWidth,
				y1: 0,
				y2: by,
			},
			bl: {
				x1: 0,
				x2: bx,
				y1: height,
				y2: by + innerBlockHeight,
			},
			br: {
				x1: width,
				x2: bx + innerBlockWidth,
				y1: height,
				y2: by + innerBlockHeight,
			},
		}, {
			x: cx,
			y: cy,

			tl: {
				x1: 0,
				x2: cx,
				y1: 0,
				y2: cy,
			},
			tr: {
				x1: width,
				x2: cx + innerBlockWidth,
				y1: 0,
				y2: cy,
			},
			bl: {
				x1: 0,
				x2: cx,
				y1: height,
				y2: cy + innerBlockHeight,
			},
			br: {
				x1: width,
				x2: cx + innerBlockWidth,
				y1: height,
				y2: cy + innerBlockHeight,
			},
		}, {
			x: dx,
			y: dy,

			tl: {
				x1: 0,
				x2: dx,
				y1: 0,
				y2: dy,
			},
			tr: {
				x1: width,
				x2: dx + innerBlockWidth,
				y1: 0,
				y2: dy,
			},
			bl: {
				x1: 0,
				x2: dx,
				y1: height,
				y2: dy + innerBlockHeight,
			},
			br: {
				x1: width,
				x2: dx + innerBlockWidth,
				y1: height,
				y2: dy + innerBlockHeight,
			},
		}, {
			x: ex,
			y: ey,

			tl: {
				x1: 0,
				x2: ex,
				y1: 0,
				y2: ey,
			},
			tr: {
				x1: width,
				x2: ex + innerBlockWidth,
				y1: 0,
				y2: ey,
			},
			bl: {
				x1: 0,
				x2: ex,
				y1: height,
				y2: ey + innerBlockHeight,
			},
			br: {
				x1: width,
				x2: ex + innerBlockWidth,
				y1: height,
				y2: ey + innerBlockHeight,
			},
		},
	]

	const [randomFrame, setRandomFrame] = React.useState(randomNumber(0, 4))

	React.useEffect(
		() => {
			setRandomFrame(randomNumber(0, 4))
		},
		[slug],
	)

	const whichFrame = innerBlock[randomFrame]

	return (
		<svg
			className={ style.Wrapper }
			preserveAspectRatio="xMinYMin"
			viewBox={ `0 0 ${width} ${height}` }
		>
			<rect
				fill="none"
				height={ height }
				stroke="black"
				strokeWidth={ strokeWidth + 10 }
				vectorEffect="non-scaling-stroke"
				width={ width }
				x={ 0 }
				y={ 0 }
			/>
			<rect
				fill="none"
				height={ innerBlockHeight }
				stroke="black"
				strokeWidth={ strokeWidth }
				vectorEffect="non-scaling-stroke"
				width={ innerBlockWidth }
				x={ whichFrame.x }
				y={ whichFrame.y }
			/>
			<line
				stroke="black"
				strokeLinecap="round"
				strokeWidth={ strokeWidth }
				vectorEffect="non-scaling-stroke"
				{ ...whichFrame.tl }
			/>
			<line
				stroke="black"
				strokeLinecap="round"
				strokeWidth={ strokeWidth }
				vectorEffect="non-scaling-stroke"
				{ ...whichFrame.tr }
			/>
			<line
				stroke="black"
				strokeLinecap="round"
				strokeWidth={ strokeWidth }
				vectorEffect="non-scaling-stroke"
				{ ...whichFrame.bl }
			/>
			<line
				stroke="black"
				strokeLinecap="round"
				strokeWidth={ strokeWidth }
				vectorEffect="non-scaling-stroke"
				{ ...whichFrame.br }
			/>
		</svg>
	)
}

PictureFrame.defaultProps = {
	height: 500,
	innerBlockSizeRatio: 0.28,
	strokeWidth: 8,
	width: 500,
}

PictureFrame.propTypes = {
	height: PropTypes.number,
	innerBlockSizeRatio: PropTypes.number,
	slug: PropTypes.string.isRequired,
	strokeWidth: PropTypes.number,
	width: PropTypes.number,
}

export default PictureFrame
