import React from 'react'
import { Helmet } from 'react-helmet'

import style from './index.module.css'

function Videos() {
	return (
		<main className={ style.Videos }>
			<Helmet title="Videos" />
			<header className={ style.Header }>
				<h2>Videos</h2>
				<h3>“An Episode”</h3>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					height="315"
					src="https://www.youtube.com/embed/En1Lt-WisOI"
					title="An Episode"
					width="560"
				/>
				<h3>“Weird Weather”</h3>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					height="315"
					src="https://www.youtube.com/embed/pex1AeSwF4Y"
					title="Weird Weather"
					width="560"
				/>
				<h3>“The Afterlives”</h3>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					height="315"
					src="https://www.youtube.com/embed/JxxYfJVBdmM"
					title="The Afterlives"
					width="560"
				/>
				<h3>“Pareidolia”</h3>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					height="315"
					src="https://www.youtube.com/embed/3iuuVdowobQ"
					title="Pareidolia"
					width="560"
				/>
				<h3>“Die Young”</h3>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					height="315"
					src="https://www.youtube.com/embed/eQjWmR_-g6Q"
					title="Die Young"
					width="560"
				/>
				<h3>“The Lucky Ones”</h3>
				<iframe
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					height="315"
					src="https://www.youtube.com/embed/hzW6qCTJp-I"
					title="The Lucky Ones"
					width="560"
				/>
			</header>
		</main>
	)
}

export default Videos
